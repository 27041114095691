
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import { PatientFormSubmissionService, EncounterService } from '@/services/api';
import { FormSubmission, Patient, Review, FormSection } from '@/models';
import { EpisodeOfCareResponseData, EpisodeOfCareEncounter as Encounter } from '@/models/episode-of-care/episode-of-care.model';
import { IOption } from '@/lib';
import {
  BaseSelect,
  BaseButton,
  SummaryItem,
  SummaryNav,
  PatientDetailsCard,
  SmartFormSummarySection,
  ReviewSummary
} from '@/lib/components';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useSessionStore } from '@/stores/session.store';
import { usePatientStore } from '@/stores/patient.store';
import { useSmartFormStore } from '@/stores/smartForm.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    ReviewSummary,
    BaseSelect,
    BaseButton,
    SmartFormSummarySection,
    SummaryNav,
    SummaryItem,
    PatientDetailsCard,
    PatientSupportingDocumentModal
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    patient: {
      type: Object,
      default: null
    },
    episodeOfCare: {
      type: Object,
      default: null
    },
    ambulatoryEncounter: {
      type: Object,
      default: null
    },
    virtualEncounter: {
      type: Object,
      default: null
    }
  }
})
export default class PatientSummary extends Vue {
  sessionStore = useSessionStore();
  patientStore = usePatientStore();
  notificationStore = useNotificationStore();
  patientId!: string;
  organisationId!: string;
  formSubmissionId = '';
  episodeOfCare!: EpisodeOfCareResponseData;
  ambulatoryEncounter!: Encounter;
  virtualEncounter!: Encounter;
  currentFormSubmissionId = '';
  formSubmissions: FormSubmission[] = [];

  review: Review | null = null;

  schema = {
    sections: [] as Array<FormSection>
  };

  patient!: Patient;
  nav = '';
  date = '';

  clinician = '';

  formSubmissionService = new PatientFormSubmissionService(this.patientId);
  encounterService = new EncounterService();

  supportingDocumentVisible = false;
  sexOptions = sexOptions();

  genderOptions = genderOptions();

  smartForm = useSmartFormStore();

  get clinic() {
    return this.episodeOfCare?.ophthalmology_details?.clinic?.name ?? '';
  }

  get consultType() {
    return this.episodeOfCare?.ophthalmology_details.consult_type ?? '';
  }

  get datesOptions(): Array<IOption> {
    return this.formSubmissions.map((submission) => ({
      value: submission.id,
      label: this.formatFormSubmissionDate(submission.created_at)
    }));
  }

  get isPrevious() {
    return this.currentFormSubmissionId !== this.formSubmissionId;
  }

  get formattedDate() {
    return this.date.length ? this.formatFormSubmissionDate(this.date) : '';
  }

  get sections(): Array<FormSection> {
    return this.schema.sections.filter(
      (section: FormSection) => !section.hide_when_readonly && section.name && this.conditionsMet(section)
    );
  }

  get useGender() {
    return this.sessionStore.currentOrganisation?.uses_gender && this.patient?.gender;
  }

  get patientInfos() {
    const nhsNumber = getNhsNumberFromPatient(this.patient);
    return this.patient
      ? {
        [this.$t('platform.patient.dob') as string]: this.formatDob(this.patient.date_of_birth),
        [(this.useGender ? this.$t('platform.patient.gender') : this.$t('platform.patient.sex')) as string]: this
          .useGender
          ? this.getGender(this.patient.gender)
          : this.getSex(this.patient.sex),
        [this.$t('platform.patient.ethnicity') as string]: this.patient.ethnicity
          ? this.patient.ethnicity.title
          : this.$t('platform.patient.not-specified'),
        ...(this.showNhsNumber ? { nhs: nhsNumber ? nhsNumber : this.$t('platform.common.none').toString() } : {}),
        ...(this.patient.minor_patients && this.patient.minor_patients.length > 0
          ? {
            [this.$t('custom.uhb.patient.alternative-ids') as string]: this.patient.minor_patients?.map(
              (minor_patient) => minor_patient.mrn
            )
          }
          : {})
      }
      : {};
  }

  get selectedDocumentId(): number | null {
    if (this.episodeOfCare?.media?.length) {
      return this.episodeOfCare.media[0].id;
    }
    return null;
  }

  get showNhsNumber() {
    return this.patientStore.showNhsNumber;
  }

  created() {
    this.currentFormSubmissionId = this.formSubmissionId;
  }

  async mounted() {
    try {
      // this.loading = true;
      await this.initFormSubmissionId();
      await this.loadFormSubmissions();
      this.$watch('currentFormSubmissionId', async () => {
        this.setNav('');
        await this.loadFormSubmission();
      });
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async initFormSubmissionId() {
    if (this.ambulatoryEncounter?.form_submission_id) {
      this.formSubmissionId = this.ambulatoryEncounter.form_submission_id;
      this.currentFormSubmissionId = this.formSubmissionId;
      await this.loadFormSubmission();
    }
  }

  async loadFormSubmissions() {
    this.formSubmissions = (await this.formSubmissionService.index()).data;
  }

  async loadFormSubmission() {
    const formSubmission = await this.formSubmissionService.fetch(this.currentFormSubmissionId);

    this.schema = formSubmission.form && formSubmission.form.schema ? formSubmission.form.schema : this.schema;
    this.date = formSubmission.created_at ? formSubmission.created_at : '';
    this.clinician = formSubmission.user ? `${formSubmission.user.given_name} ${formSubmission.user.family_name}` : '';
    this.smartForm.answers = {
      ...this.smartForm.answers,
      [this.currentFormSubmissionId]: formSubmission.answers
    };
  }

  onCopy(success: boolean) {
    if (success) {
      this.notificationStore.addSuccessNotification({
        title: this.$t('custom.uhb.review.summary.success-message')
      });
    } else {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.review.summary.error-message')
      });
    }
  }

  setNav(value: string) {
    this.nav = value;
    const ref = this.$refs[value];
    if (ref) {
      const element = (Array.isArray(ref) ? ref[0] : ref) as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  conditionsMet(section: FormSection) {
    return this.smartForm.getItemConditionsMet(this.currentFormSubmissionId, section.conditions);
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  getSex(sex?: string): string | TranslateResult | undefined {
    const sexOption = this.sexOptions.find((option) => option.value === sex);
    return sexOption ? sexOption.label : sex;
  }

  getGender(gender?: string): string | TranslateResult | undefined {
    const genderOption = this.genderOptions.find((option) => option.value === gender);
    return genderOption ? genderOption.label : gender;
  }

  formatFormSubmissionDate(date: string) {
    return dayjs(date).format('DD MMM YYYY, [at] HH:MM [(UTC)]');
  }

  viewSupportingDocument() {
    this.supportingDocumentVisible = true;
  }

  closeSupportingDocumentModal() {
    this.supportingDocumentVisible = false;
  }
}
